/**
 * Created by yasui on 2015/12/15.
 */

/**
 * 全角から半角にする拡張
 * @returns {string}
 */
String.prototype.toHankaku = function () {
    var h = '';
    this.split('').forEach(function (s) {
        var code = s.charCodeAt(0);
        if (0xFEE0 <= code) {
            h += String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
        } else {
            h += s;
        }
    });
    return h;
};

/**
 *
 * @param {string} text
 * @returns {int}
 */
function card_year(text) {
    // "mm/yy" なので、2000 + y させる
    return parseInt(text.split('/')[1]) + 2000;
}

/**
 *
 * @param {string} text
 * @returns {int}
 */
function card_month(text) {
    return parseInt(text.split('/')[0]);
}

/**
 *
 * @param {string} text
 * @returns {string}
 */
function card_format(text) {
    return text.replace(/ /g, '-');
}

/**
 * カード決済でTOKENの取得をして、エラーが起きた時は出力して、成功した時はフォーム送信をする。
 *
 * @param FORM
 * @param OVERLAY
 * @param input_number
 * @param input_exp
 * @param input_name
 * @param input_cvc
 * @param error_text
 */
function card_checkout(FORM, OVERLAY, input_number, input_exp, input_name, input_cvc, error_text) {
    var webpayResponseHandler = function (status, response) {
        if (response.error) {
            // 必要に応じてエラー処理を入れてください
            //console.log(response.error);

            FORM.find("button").prop("disabled", false);

            card_error(response.error, error_text, input_number, input_exp, input_name, input_cvc);

            OVERLAY.hide();
        } else {
            // 伝送させたくない情報をフォームから削除する
            $(input_number).removeAttr("name");
            $(input_cvc).removeAttr("name");
            $(input_exp).removeAttr("name");

            var token = response.id;
            var input = document.createElement("input");
            input.type = "hidden";
            input.name = "token";
            input.value = token;
            $(input).appendTo(FORM);

            FORM.get(0).submit();
        }
    };

    WebPay.setPublishableKey($('meta[name=WebPayAPIKey]').attr('content'));
    WebPay.setLanguage('ja');

    $(error_text).hide();
    $('.has-error').removeClass('has-error');

    var expiry = $(input_exp).val();

    WebPay.createToken({
        number: card_format($(input_number).val()),
        name: $(input_name).val(),
        cvc: $(input_cvc).val(),
        exp_month: card_month(expiry),
        exp_year: card_year(expiry)
    }, webpayResponseHandler);
}

/**
 * PayJPのエラーを日本語化
 * @type {{card_declined: {message: string, param: string}, expired_card: {message: string, param: string}, invalid_cvc: {message: string, param: string}, processing_error: {message: string, param: string}}}
 */
var PayJPErrorTypeMap = {
    'card_declined': {
        'message': 'このカードはご利用になれません。カード情報を見直すか、他のカードをご利用ください。',
        'param': 'number'
    },
    'expired_card': {
        'message': '利用期限が切れています。利用期限を見直すか、他のカードをご利用ください。',
        'param': 'number'
    },
    'invalid_cvc': {
        'message': 'セキュリティコードに間違いがあります。再度ご確認をお願いします。',
        'param': 'cvc'
    },
    'processing_error': {
        'message': 'カード決済会社でエラーが発生しております。お手数ですが、数時間後に再度お試しください。',
        'param': 'number'
    },
    'invalid_expiry_year': {
        'message': '有効期限　年　に間違いがあります。再度ご確認をお願いします。',
        'param': 'exp_year'
    },
    'invalid_expiration_date': {
        'message': '有効期限に間違いがあります。再度ご確認をお願いします。',
        'param': 'exp_year'
    },
    'invalid_expiry_month': {
        'message': '有効期限　月　に間違いがあります。再度ご確認をお願いします。',
        'param': 'exp_month'
    },
    'unacceptable_brand': {
        'message': '利用できないブランドのカードです。Visa, Master, JCB, American Express, Diners Club, Discover のいずれかのカードをご利用下さい。',
        'param': 'number'
    },
    'invalid_number': {
        'message': 'カード番号に入力間違いがあります。再度ご確認をお願いします。',
        'param': 'number'
    }
};

/**
 *
 * @param {{message: {string}, param: {string}, type: {string}}} error
 * @param error_field_dom
 * @param input_number
 * @param input_exp
 * @param input_name
 * @param input_cvc
 */
function card_error(error, error_field_dom, input_number, input_exp, input_name, input_cvc) {
    console.log(error);
    $(error_field_dom).text(error.message).show();
    var dom = function () {
        var buff = {
            exp_month: input_exp,
            exp_year: input_exp,
            number: input_number,
            name: input_name,
            cvc: input_cvc
        };
        return buff[error.param];
    };
    $(dom()).parent().addClass('has-error');
}

/**
 *
 * @param {object} input_number
 * @param {object} input_exp
 * @param {object} input_name
 * @param {object} input_cvc
 */
var CardInputChecker = function (input_number, input_exp, input_name, input_cvc) {
    this.number = $(input_number).val();
    this.exp = $(input_exp).val();
    this.name = $(input_name).val();
    this.cvc = $(input_cvc).val();
};

/**
 *
 * @returns {{trust: {bool}, message: {string}, param: {string}, type: {string}}}
 */
CardInputChecker.prototype.check = function () {
    /**
     *
     * @param {bool} trust
     * @param {string} message
     * @param {string} name
     * @returns {{trust: {bool}, message: {string}, param: {string}, type: {string}}}
     */
    var resulter = function (trust, message, name) {
        return {
            'trust': trust,
            'message': message,
            'param': name,
            'type': name
        };
    };

    if (/^\s*$/.test(this.number)) {
        return resulter(false, 'カード番号を入力してください。再度ご確認をお願いします', 'number');
    }

    if (/^\s*$/.test(this.name.toHankaku())) {
        return resulter(false, 'カード名義人は英語で記入してください。再度ご確認をお願いします。', 'name');
    }

    console.log(this.name.toHankaku());
    if (/^[a-zA-Z]+[a-zA-Z\s]+$/.test(this.name.toHankaku()) === false) {
        return resulter(false, 'カード名義人は英語で記入してください。再度ご確認をお願いします。', 'name');
    }

    if (/^\s*$/.test(this.cvc)) {
        return resulter(false, 'CVCを入力してください。再度ご確認をお願いします。', 'cvc');
    }

    if (/^\s*$/.test(this.exp)) {
        return resulter(false, '有効期限を入力してください。再度ご確認をお願いします', 'exp_year');
    }

    if (/^\s*[01][1-9]\s*\/\s*[0-9][0-9]\s*$/.test(this.exp) === false) {
        return resulter(false, '有効期限は 月/年 で記入してください。再度ご確認をお願いします', 'exp_year');
    }

    var expiry_spl = this.exp.split(/\//g);
    if (expiry_spl.length !== 2) {
        return resulter(false, '有効期限は 月/年 のフォーマットで記述してください。再度ご確認をお願いします', 'exp_year');
    }

    return resulter(true, '', '');
};

/**
 * カード決済でTOKENの取得をして、エラーが起きた時は出力して、成功した時はフォーム送信をする。
 *
 * @param FORM
 * @param OVERLAY
 * @param input_number
 * @param input_exp
 * @param input_name
 * @param input_cvc
 * @param error_text
 */
function payjp_checkout(FORM, OVERLAY, input_number, input_exp, input_name, input_cvc, error_text) {

    /**
     *
     * @param {{ message:{string}, type:{string}, param:{string} }} error_txt
     */
    var card_error_display = function (error_txt) {
        FORM.find("button").prop("disabled", false);
        card_error(error_txt, error_text, input_number, input_exp, input_name, input_cvc);
        OVERLAY.hide();
    };

    var payjpResponseHandler = function (status, response) {
        if (response.error) {
            // 必要に応じてエラー処理を入れてください
            var error = response.error;
            if (PayJPErrorTypeMap[error.code]) {
                error = PayJPErrorTypeMap[error.code];
            }
            card_error_display(error);
        } else {
            // 伝送させたくない情報をフォームから削除する
            $(input_number).removeAttr("name");
            $(input_cvc).removeAttr("name");
            $(input_exp).removeAttr("name");

            var token = response.id;
            var input = document.createElement("input");
            input.type = "hidden";
            input.name = "token";
            input.value = token;
            $(input).appendTo(FORM);

            FORM.get(0).submit();
        }
    };

    var card_reformat = function card_format(text) {
        return text.replace(/ /g, '');
    };

    Payjp.setPublicKey($('meta[name=PayJPAPIKey]').attr('content'));

    $(error_text).hide();
    $('.has-error').removeClass('has-error');

    var checker = new CardInputChecker(input_number, input_exp, input_name, input_cvc);
    var result = checker.check();
    if (result.trust === false) {
        card_error_display(result);
        return;
    }

    var expiry = $(input_exp).val();

    var requests = {
        number: card_reformat($(input_number).val()),
        cvc: $(input_cvc).val(),
        exp_month: card_month(expiry),
        exp_year: card_year(expiry),
        name: $(input_name).val()
    };

    Payjp.createToken(requests, payjpResponseHandler);
}

